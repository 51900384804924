import type { ReactNode } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'
import { ChevronDown, ChevronUp } from 'lucide-react'

import { cn } from '../../../lib/utils'

type ExpandBaseProps = VariantProps<typeof expandVariants>

const expandVariants = cva('', {
  variants: {
    variant: {
      underline: 'border-b border-border group py-s hover:border-input',
      card: 'rounded-md border bg-surface-card px-m py-s hover:border-input transition-colors duration-200 ease-in-out group open:pb-m',
    },
  },
})

export interface ExpandProps extends ExpandBaseProps {
  children: ReactNode
  className?: string
}

/**
 * __Expand__
 *
 * Vanilla component that allows you to expand and collapse content.
 *
 */
export const Expand = ({ children, className, variant }: ExpandProps) => {
  return (
    <details className={cn(expandVariants({ variant }), className)}>
      {children}
    </details>
  )
}

Expand.displayName = 'Expand'

/**
 * __ExpandHeader__
 *
 * Header for the expand component. Required to be used with the `Expand` component.
 */
export const ExpandHeader = ({ children }: { children: ReactNode }) => {
  return (
    <summary
      className={cn(
        'flex w-full items-center justify-between hover:cursor-pointer',
      )}
    >
      <div className="flex w-full items-center justify-between">{children}</div>
      <ChevronDown
        className="ml-m min-w-4 text-subtle group-open:hidden"
        size={16}
      />
      <ChevronUp
        className="ml-m hidden min-w-4 text-subtle group-open:block"
        size={16}
      />
    </summary>
  )
}

ExpandHeader.displayName = 'ExpandHeader'
